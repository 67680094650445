import React from 'react';
import { Box } from 'rebass';

export default props => (
  <Box
    sx={{
      maxWidth: 1400,
      mx: 'auto',
      px: '5%',
    }}
    {...props}
  />
);
