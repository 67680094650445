import styled from 'styled-components';
import { Heading } from 'rebass';

// import { colors } from '../../theme';

export default styled.h1`


font-family: 'Source Sans Pro' !Important;
font-size: 48px !Important;
margin-top: 0;
margin-bottom: 0;
font-style: italic;
font-display: swap;
-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;



  /* color: ${colors.gray9}; */

  /* &:hover {
    color: ${colors.base};
    cursor: pointer;
  } */
`;
