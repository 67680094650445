import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import * as constants from '../utils/constants';

const Drawer = styled.div`
  left: 10%;
  position: relative;
  width: 80%;
  z-index: 1000;
`;

const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 80% auto;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
  background-color: ${props =>
    constants.BACKGROUND_COLORS[props.messageType] ||
    constants.BACKGROUND_COLOR_ERROR};
  color: ${props =>
    constants.TEXT_COLORS[props.messageType] || constants.COLOR_ERROR};
  border-radius: 0.5em;
  font-family: 'Source Sans Pro', sans serif;
  font-style: italic;
`;

const Button = styled.button`
  border-radius: 0.5em;
  border-color: rgb(255, 255, 255, 0.3);
  background-color: rgb(0, 0, 0, 0.25);
  color: rgb(0, 0, 0, 0.67);
  margin: 0.5em;
  height: 3em;
  &:hover {
    color: rgb(0, 0, 0, 1);
    cursor: pointer;
  }
  font-family: 'Source Sans Pro', sans serif;
  font-style: italic;
`;

const Text = styled.p`
  margin: 1em;
`;

const Message = ({ message, messageType, dismiss }) => (
  <Drawer>
    {message && (
      <MessageContainer messageType={messageType}>
        <Text>{message}</Text>
        <Button onClick={dismiss}>Close</Button>
      </MessageContainer>
    )}
  </Drawer>
);

Message.propTypes = {
  dismiss: PropTypes.func.isRequired,
  message: PropTypes.string,
  messageType: PropTypes.string,
};

Message.defaultProps = {
  message: '',
  messageType: 'red',
};

export default Message;
