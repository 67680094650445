export const API_HOST = 'https://api.modarri.com';
export const API_URL = `${API_HOST}/api/v1/`;
export const API_TOKEN_URL = `${API_HOST}/api/token/`;
export const PASSWORD_RESET_URL = `${API_HOST}/password_reset/`;
export const CONFIGURATOR_URL = `https://shop.modarri.com/products/configurator`;

export const MESSAGE_TYPE_SUCCESS = 'success';
export const MESSAGE_TYPE_INFO = 'info';
export const MESSAGE_TYPE_WARNING = 'warning';
export const MESSAGE_TYPE_ERROR = 'error';
export const MESSAGE_EMAIL_CONFIRMATION_ERROR =
  'Your email could not be confirmed';
export const MESSAGE_LOGIN_ERROR =
  'The email / password you entered is incorrect';
export const MESSAGE_SIGNUP_ERROR =
  'We were unable to sign you up. Please try again';
export const MESSAGE_RESET_PASSWORD_ERROR =
  'We were unable to reset your password. Please try again';
export const MESSAGE_ADD_DRIVER_ERROR =
  'We were unable to create a new Driver. Please try again';
export const MESSAGE_DELETE_DRIVER_ERROR =
  'We were unable to delete the Driver. Please try again';
export const MESSAGE_DELETE_LAST_DRIVER_ERROR =
  'Users cannot delete their only driver.';
export const MESSAGE_EDIT_DRIVER_ERROR =
  'We were unable to edit the Driver. Please try again';
export const MESSAGE_GET_DRIVERS_ERROR =
  'We were unable to get the list of Drivers. Please try again';
export const MESSAGE_DOWNLOAD_LICENSE_ERROR =
  'We were unable to download the license. Please try again';
export const MESSAGE_CHECK_LICENSE_ERROR =
  "An error occurred while checking the driver's license. Please try again";
export const MESSAGE_GET_GARAGE_ERROR =
  'An error occurred while getting your garage. Please try again';
export const MESSAGE_ADD_GARAGE_ERROR =
  'An error occurred while adding car to your garage. Please try again';
export const MESSAGE_REGISTER_CAR_ERROR =
  'An error occurred while registering the car. Please try again';
export const MESSAGE_DELETE_CAR_ERROR =
  'An error occurred while deleting the car. Please try again';
export const MESSAGE_EDIT_CAR_ERROR =
  'An error occurred while editing the car. Please try again';

export const MESSAGE_LOGIN_SUCCESS = 'Login successful';
export const TOKEN_REFRESH_ERROR = 'Login has expired, please log in again.';
export const MESSAGE_GENERIC_ERROR = 'An unexpected error has occurred';
export const MESSAGE_CONFIRM_DELETE_DRIVER =
  'Are you sure you want to delete driver?';
export const MESSAGE_CAR_MOVE_SUCCESS =
  "{0} successfully moved to {1}'s garage.";

export const BACKGROUND_COLOR_SUCCESS = '#dff0d8';
export const BACKGROUND_COLOR_INFO = '#d9edf7';
export const BACKGROUND_COLOR_WARNING = '#fcf8e3';
export const BACKGROUND_COLOR_ERROR = '#f2dede';
export const COLOR_SUCCESS = '#3c763d';
export const COLOR_INFO = '#31708f';
export const COLOR_WARNING = '#8a6d3b';
export const COLOR_ERROR = '#a94442';
export const TEXT_COLORS = {
  [MESSAGE_TYPE_SUCCESS]: COLOR_SUCCESS,
  [MESSAGE_TYPE_INFO]: COLOR_INFO,
  [MESSAGE_TYPE_WARNING]: COLOR_WARNING,
  [MESSAGE_TYPE_ERROR]: COLOR_ERROR,
};
export const BACKGROUND_COLORS = {
  [MESSAGE_TYPE_SUCCESS]: BACKGROUND_COLOR_SUCCESS,
  [MESSAGE_TYPE_INFO]: BACKGROUND_COLOR_INFO,
  [MESSAGE_TYPE_WARNING]: BACKGROUND_COLOR_WARNING,
  [MESSAGE_TYPE_ERROR]: BACKGROUND_COLOR_ERROR,
};

export const DRIVERS_NAMES = [
  'Ace',
  'Jett',
  'Demetri',
  'Maverick',
  'Nico',
  'Kit',
  'Dusty',
  'Flint',
  'Jed',
];

export const REFRESH_EXPIRATION_DAYS = 1;
