import { setMessage } from './message';
import * as constants from '../utils/constants';
import { verifyToken, refreshToken } from './auth';

const request = async (route, init, onError, isBlob) => {
  const response = await fetch(route, init);
  try {
    let data;
    if (isBlob) {
      data = await response.blob();
    } else {
      data = await response.json();
    }
    if (response.ok) return data;
    return onError && onError(data);
  } catch (e) {
    if (!response.ok) return onError && onError(e);
  }
  return null;
};

const onError = async (store, fields, defaultErrorMessage, e) => {
  let message = defaultErrorMessage;
  fields.forEach(field => {
    if (e[field] && Array.isArray(e[field])) {
      e[field].forEach(m => {
        message = message.concat(`\n${m}`);
      });
    }
  });
  await setMessage(store, message, constants.MESSAGE_TYPE_ERROR);
  return null;
};

export const authRequest = (
  store,
  route,
  method,
  data,
  defaultErrorMessage
) => {
  let fields = [];
  const url = `${constants.API_TOKEN_URL}${route}`;
  const init = {
    headers: { 'Content-Type': 'application/json' },
    method,
  };
  if (data) {
    init.body = JSON.stringify(data);
    fields = Object.keys(data);
  }
  const errorHandler = e => onError(store, fields, defaultErrorMessage, e);
  return request(url, init, errorHandler);
};

export const passwordRequestReset = (store, data, defaultErrorMessage) => {
  let fields = [];
  const url = `${constants.PASSWORD_RESET_URL}request/`;
  const init = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  };
  if (data) {
    init.body = JSON.stringify(data);
    fields = Object.keys(data);
  }
  const errorHandler = e => onError(store, fields, defaultErrorMessage, e);
  return request(url, init, errorHandler);
};

export const passwordDoReset = (store, data, defaultErrorMessage) => {
  const url = `${constants.PASSWORD_RESET_URL}reset/`;
  const init = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data),
  };
  const fields = Object.keys(data);
  const errorHandler = e => onError(store, fields, defaultErrorMessage, e);
  return request(url, init, errorHandler);
};

export default async (store, config) => {
  const {
    route,
    method,
    data = null,
    isBlob = false,
    defaultErrorMessage = constants.MESSAGE_GENERIC_ERROR,
  } = config;
  let { token } = store.state;
  let fields = [];
  const url = `${constants.API_URL}${route}`;
  const isTokenValid = await verifyToken(store);
  if (token && !isTokenValid) {
    token = await refreshToken(store);
  }
  const init = {
    headers: { 'Content-Type': 'application/json' },
    method,
  };
  if (token) {
    init.headers.Authorization = `JWT ${token}`;
  }
  if (data) {
    init.body = JSON.stringify(data);
    fields = Object.keys(data);
  }
  const errorHandler = e => onError(store, fields, defaultErrorMessage, e);
  return request(url, init, errorHandler, isBlob);
};
