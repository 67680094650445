import React from "react";
import { Flex, Box } from "rebass";
import Container from "./styles/Container";
import logOutboundLink from "../utils/log-outbound-link";
import { Link } from "gatsby";
import styled from "styled-components";
import Text from "./styles/Text";
import track from "../images/track.png";
import insta from "../images/insta.svg";
import fb from "../images/fb_white.svg";
import YoutubeBadge from "../images/youtube_badge_white.svg";

const FooterText = styled(Text)`
  font-size: 1.2em;
  font-family: "Source Sans Pro";
  color: white;
`;

const FooterBox = styled(Flex)`
  z-index: 10;
`;

const FooterBanner = styled.div`
  min-height: 450px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: rgb(123, 140, 149);
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 0;
`;

const SocialImage = styled.img`
  color: white;
  width: 20px;
  margin-bottom: -4px;
  &:hover {
    transform: translate(1px, 1px);
  }
`;

const SocialLink = styled.a`
  margin-left: 5px;
  padding-right: 5px;
`;

const FBSocialImage = styled.img`
  width: 18px;
  margin-bottom: -2px;
  &:hover {
    transform: translate(1px, 1px);
    color: rgb(55, 65, 69);
  }
`;

const FooterInternal = styled(Link)`
  color: white;
  font-family: "Open Sans", sans-serif;
  &:hover {
    color: #d5dadd;
  }
`;

const FooterOverlay = styled.img`
  position: absolute;
  z-index: 1;
  width: 60%;
  left: 0;
  max-height: 450px;
`;

const FooterExternal = styled.a`
  color: white;
  font-family: "Open Sans", sans-serif;
  &:hover {
    color: #d5dadd;
  }
`;

class Footer extends React.Component {
  render() {
    const getYear = new Date().getFullYear();
    return (
      <div>
        <FooterBanner bg="base">
          <FooterOverlay src={track} />
          <FooterBox flexWrap="wrap" pt="" mb="10px" width="60%">
            <Box width={[1, 1, 1 / 2]} p={1} color="white">
              <Box width={1} px={1} py={3}>
                <FooterText>CONTACT US</FooterText>
              </Box>
              <Box width={1} p={1}>
                <FooterExternal href="tel:+18314212363">
                  (831) 421.2363
                </FooterExternal>
              </Box>
              <Box width={1} p={1}>
                <FooterExternal href="mailto:Support@thoughtfulltoys.com">
                  Support@thoughtfulltoys.com
                </FooterExternal>
              </Box>
              <Flex flexWrap="wrap" width={[1, 1, 1 / 2]} p={1}>
                <FooterExternal
                  href="https://goo.gl/maps/1eisf6xmDCkZGKty5"
                  target="_blank"
                >
                  303 Potrero St. 29-101 Santa Cruz, CA 95060
                </FooterExternal>
              </Flex>
            </Box>

            <Box width={[1, 1 / 2]} p={1} mb="25px" color="white">
              <Box width={1} px={1} py={3}>
                <FooterText>RESOURCES</FooterText>
              </Box>
              <Box width={1} p={1}>
                <FooterInternal to="/store-locator">
                  Store Locator
                </FooterInternal>
              </Box>
              <Box width={1} p={1}>
                <FooterInternal to="/privacy"> Privacy Policy</FooterInternal>
              </Box>
              <Box width={1} p={1}>
                <FooterInternal to="/mapp">MAPP Policy</FooterInternal>
              </Box>
              <Box width={1} p={1}>
                <FooterInternal to="/international-sales-team">
                  Become a Dealer
                </FooterInternal>
              </Box>
            </Box>
            <SocialLink
              href="https://www.facebook.com/modarri/"
              target="_blank"
              color="white"
            >
              <FBSocialImage src={fb} />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/modarri"
              target="_blank"
            >
              <SocialImage src={insta} />
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/channel/UC9o78LSi1BSOBocWXb-QKow"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImage src={YoutubeBadge} />
            </SocialLink>
          </FooterBox>
        </FooterBanner>
      </div>
    );
  }
}

export default Footer;
