import styled from 'styled-components';
import { Text } from 'rebass';
import { textAlign } from 'styled-system';

import lineHeight from '../../utils/line-height';

export default styled(Text)`
  ${textAlign};
  ${lineHeight};
  color: rgb(55, 65, 69);
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !Important;
`;
