import request from './request';

export default async store => {
  const { avatars: _avatars } = store.state;
  if (!_avatars || !Object.keys(_avatars).length) {
    const config = { route: `driver_avatar/`, method: 'GET' };
    const data = await request(store, config);
    if (data) {
      const avatars = data.reduce(
        (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
        {}
      );
      await store.setState({ avatars });
      return avatars;
    }
  }
  return _avatars;
};
