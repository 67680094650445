import request from './request';

export default async store => {
  const { storeFronts: _storeFronts } = store.state;
  const config = { route: `storefront/`, method: 'GET' };
  if (!_storeFronts || !Object.keys(_storeFronts).length) {
    const data = await request(store, config);
    if (data) {
      const storeFronts = data.reduce(
        (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
        {}
      );
      await store.setState({ storeFronts });
      return storeFronts;
    }
  }
  return _storeFronts;
};
