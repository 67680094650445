import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink, navigate, StaticQuery, graphql } from "gatsby";
import Hide from "hidden-styled";
import styled from "styled-components";
import { Location } from "@reach/router";

import Img from "gatsby-image";
import insta from "../images/insta.svg";
import fb from "../images/fb.svg";
import YoutubeBadge from "../images/youtube_badge.svg";
import useGlobal from "../store";

const HeaderExtLink = styled.a`
  color: #7b8c95;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  font-size: 17px;
  font-style: italic;
  margin-left: 1.5vw;
  font-display: swap;
  &:hover {
    color: rgb(55, 65, 69);
    cursor: pointer;
  }
`;

const MenuLink = styled(GatsbyLink)`
  color: #7b8c95;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 17px;
  margin-left: 1.5vw;
  font-display: swap;
  &:hover {
    color: rgb(55, 65, 69);
    cursor: pointer;
  }
`;

const MenuMobile = styled(GatsbyLink)`
  color: #7b8c95;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 22px;
  margin-left: 1.5vw;
  margin-right: 20px;
  font-display: swap;
  &:hover {
    color: rgb(55, 65, 69);
    cursor: pointer;
  }
`;

const NavContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(20%, 1fr) auto;
  justify-content: space-between;
  max-width: 1024px !Important;
  padding: 12px 12px 12px 12px;
  align-items: center;
  border: outline;
  border-bottom-width: 5px;
  border-color: #7b8c95;
`;

const SocialLink = styled.a`
  margin-left: 5px;
  padding-right: 5px;
`;

const SocialLeftLink = styled.a`
  margin-left: 35px;
  padding-right: 5px;
`;

const LogoImage = styled(Img)`
  cursor: pointer;
`;

const SocialImage = styled.img`
  color: #7b8c95;
  width: 20px;
  margin-bottom: -4px;
  &:hover {
    transform: translate(1px, 1px);
  }
`;

const FBSocialImage = styled.img`
  color: #7b8c95;
  width: 18px;
  margin-bottom: -2px;
  &:hover {
    transform: translate(1px, 1px);
    color: rgb(55, 65, 69);
  }
`;

const NavBorder = styled.div`
  display: flex;
  justify-content: center;
  border-bottom-width: 5px;
  border-bottom: 5px solid #7b8c95;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Header = ({ data, location }) => {
  const [state, actions] = useGlobal();
  const { token } = state;
  const { logout } = actions;

  return (
    <NavBorder>
      <NavContainer>
        <div
          style={{ paddingTop: "3px" }}
          onClick={() => navigate("/")}
          onKeyUp={e => e.key === "Enter" && navigate("/")}
          role="link"
          tabIndex={0}
        >
          <LogoImage fixed={data.childImageSharp.fixed} />
        </div>
        <Flex>
          <Hide xs>
            <HeaderExtLink
              is={GatsbyLink}
              href="https://shop.modarri.com/"
              activeStyle={{ color: "rgb(55, 65, 69)" }}
            >
              SHOP
            </HeaderExtLink>
          </Hide>
          <Hide xs>
            {/* <MenuLink
              is={GatsbyLink}
              to="/learn-more"
              activeStyle={{ color: "rgb(55, 65, 69)" }}
            >
              LEARN MORE
            </MenuLink> */}
          </Hide>
          <Hide xs>
            <MenuLink
              is={GatsbyLink}
              to="/garage"
              activeStyle={{ color: "rgb(55, 65, 69)" }}
            >
              MY GARAGE
            </MenuLink>
          </Hide>
          <Hide xs>
            <MenuLink
              is={GatsbyLink}
              to="/blog"
              activeStyle={{ color: "rgb(55, 65, 69)" }}
            >
              BLOG
            </MenuLink>
          </Hide>
          <Hide xs>
            <MenuLink
              is={GatsbyLink}
              to="/about"
              activeStyle={{ color: "rgb(55, 65, 69)" }}
            >
              ABOUT US
            </MenuLink>
          </Hide>
          <Hide xs>
            {token ? (
              <MenuLink
                is={GatsbyLink}
                to="/"
                onClick={logout}
                activeStyle={{ color: "rgb(55, 65, 69)" }}
              >
                LOGOUT
              </MenuLink>
            ) : (
              <MenuLink
                is={GatsbyLink}
                to="/login"
                activeStyle={{ color: "rgb(55, 65, 69)" }}
              >
                LOGIN
              </MenuLink>
            )}
          </Hide>
          <Hide xs sm>
            {/* Add FB */}
            <SocialLeftLink
              href="https://www.facebook.com/modarri/"
              target="_blank"
            >
              <FBSocialImage src={fb} />
            </SocialLeftLink>
            <SocialLink
              href="https://www.instagram.com/modarri"
              target="_blank"
            >
              <SocialImage src={insta} />
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/channel/UC9o78LSi1BSOBocWXb-QKow"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImage src={YoutubeBadge} />
            </SocialLink>
          </Hide>

          <Hide sm md lg>
            <Location>
              {() => {
                const checkDefine = !location ? null : location.pathname;
                if (checkDefine === "/menu") {
                  // console.log('Go Back');
                  return (
                    <div>
                      <MenuLink onClick={() => navigate("/")}>CLOSE</MenuLink>
                    </div>
                  );
                }
                return (
                  <div>
                    <MenuMobile is={GatsbyLink} to="/menu">
                      MENU
                    </MenuMobile>
                  </div>
                );
              }}
            </Location>
          </Hide>
        </Flex>
      </NavContainer>
    </NavBorder>
  );
};

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  data: PropTypes.shape({
    childImageSharp: PropTypes.object
  }).isRequired
};

Header.defaultProps = {
  location: {
    pathname: ""
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderFile {
        file(relativePath: { eq: "images/logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 170) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Header data={data.file} />}
  />
);
