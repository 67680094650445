// const palx = require('palx');

// export const space = [0, 4, 8, 12, 16, 24, 32, 64];

// export const font = 'Open Sans';

// export const weights = [900, 1000];

export const padding = '60px';

// export const lineHeights = {
//   none: 1,
//   tight: 1.25,
//   normal: 1.4,
//   loose: 2,
// };

// const palette = palx('#7b8c95');

// const flattened = Object.keys(palette).reduce((accumlator, key) => {
//   const value = palette[key];

//   if (Array.isArray(value)) {
//     accumlator[key] = value[5];
//     value.forEach((element, i) => {
//       accumlator[key + i] = element;
//     });
//   } else {
//     accumlator[key] = value;
//   }

//   return accumlator;
// }, {});

// export const colors = {
//   white: '#fff',
//   ...flattened,
// };

// export const shadows = {
//   none: 'none',
//   small: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)',
//   medium: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.1)',
//   outline: `0 0 0 3px ${colors.base}`,
// };

// This is for the button setting:
export const radii = [0, 2, 4];

// This is the master radius in px
export const radius = '5px';

// export default {
//   space,
//   font,
//   weights,
//   lineHeights,
//   colors,
//   shadows,
//   radii,
//   radius,
//   padding,
// };

export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: '#07c',
    lightgray: '#f6f6ff',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 64],
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Opens Sans, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
  },
};
