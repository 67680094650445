import React, { Fragment, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { func, shape, string } from 'prop-types';
import Helmet from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'emotion-theming';
import theme from '../theme';
import Header from '../components/Header';

import Footer from '../components/Footer';
import useGlobal from '../store';
import Message from './Message';

const propTypes = {
  children: func.isRequired,
  data: shape({
    site: shape({
      siteMetadata: shape({
        title: string,
        description: string,
        siteUrl: string,
      }),
    }),
  }).isRequired,
};

/* eslint-disable no-unused-expressions */

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    transition: inherit;
  }
  body {
    margin: 0;
    transition: color 0.15s, background-color 0.15s, box-shadow 0.15s;
  }
  a {
    text-decoration: none;
  }
`;

export default ({ children, location, props }) => {
  const [state, actions] = useGlobal();
  const { message, messageType, token } = state;
  const {
    clearMessage,
    getAvatars,
    getDrivers,
    getStoreFronts,
    loadAuthData,
    loadDriver,
  } = actions;

  useEffect(() => {
    loadAuthData();
    loadDriver();
  }, []);

  useEffect(
    () => {
      if (token) {
        getDrivers();
        getAvatars();
        getStoreFronts();
      }
    },
    [token]
  );

  useEffect(
    () => {
      clearMessage();
    },
    [location]
  );

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
              image
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <Fragment>
            <GlobalStyle />
            <Helmet>
              <html lang="en" />

              <title>{data.site.siteMetadata.title}</title>

              <meta
                name="description"
                content={data.site.siteMetadata.description}
              />

              <meta
                property="og:title"
                content={data.site.siteMetadata.title}
              />
              <meta property="og:image" content="" />
              <meta
                property="og:description"
                content={data.site.siteMetadata.description}
              />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:creator" content="@" />
              <meta name="twitter:image" content="" />

              <link
                rel="icon"
                type="image/png"
                href="favicon-32x32.png"
                sizes="32x32"
              />
              <link
                rel="icon"
                type="image/png"
                href="favicon-16x16.png"
                sizes="16x16"
              />
            </Helmet>
            <Header />
            <main>
              <Message
                message={message}
                messageType={messageType}
                dismiss={clearMessage}
              />
              {children}
            </main>
            <Footer />
          </Fragment>
        </ThemeProvider>
      )}
    />
  );
};
