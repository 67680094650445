export const setMessage = async (store, message, messageType) => {
  await store.setState({ message, messageType });
  return message;
};

export const clearMessage = async store => {
  const message = '';
  const messageType = '';
  store.setState({ message, messageType });
  return { message, messageType };
};
