import request, {
  authRequest,
  passwordDoReset,
  passwordRequestReset,
} from './request';
import { setMessage } from './message';
import * as constants from '../utils/constants';
import { clearDriver } from './driver';

export const confirmEmail = async (store, token, uid) => {
  const config = {
    route: `activate/`,
    method: 'POST',
    data: { token, uid },
    defaultErrorMessage: constants.MESSAGE_EMAIL_CONFIRMATION_ERROR
  };
  const data = await request(store, config);
  if (data && data.message) {
    await setMessage(store, data.message, constants.MESSAGE_TYPE_SUCCESS);
  }
  return data;
};

export const clearToken = async store => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  localStorage.removeItem('expiration');
  await store.setState({ token: '', refresh: '', expiration: '' });
  return '';
};

export const clearUsername = async store => {
  localStorage.removeItem('username');
  await store.setState({ username: '' });
  return '';
};

export const loadToken = async store => {
  const token = localStorage.getItem('token');
  await store.setState({ token, loadingToken: false });
  return token;
};

export const loadAuthData = async store => {
  let token = localStorage.getItem('token') || '';
  const refresh = localStorage.getItem('refresh') || '';
  const username = localStorage.getItem('username') || '';
  const expiration = localStorage.getItem('expiration') || '';
  if (refresh && expiration) {
    const now = new Date();
    const expires = new Date(expiration);
    if (expires > now) {
      await store.setState({ refresh, username, expiration });
      token = await refreshToken(store);
    }
  } else {
    await logout(store);
  }

  await store.setState({ loadingToken: false });
  return token;
};

export const logout = async store => {
  await clearToken(store);
  await clearUsername(store);
  await clearDriver(store);
  return { driver: {}, username: '', token: '' };
};

export const signUp = async (store, credentials) => {
  const config = {
    route: `register/`,
    method: 'POST',
    data: credentials,
    defaultErrorMessage: constants.MESSAGE_SIGNUP_ERROR
  };
  const data = await request(store, config);
  if (data && data.message) {
    await setMessage(store, data.message, constants.MESSAGE_TYPE_SUCCESS);
  }
  return data;
};

export const setToken = async (store, credentials) => {
  const data = await authRequest(store,
      ``,
      'POST',
      credentials,
      constants.MESSAGE_LOGIN_ERROR
  );
  if (data) {
    const { access: token, refresh } = data;
    const date = new Date();
    date.setDate(date.getDate() + constants.REFRESH_EXPIRATION_DAYS);
    const expiration = date.toISOString();
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('username', credentials.username);
    localStorage.setItem('expiration', expiration);
    await store.setState({
      token,
      refresh,
      username: credentials.username,
      expiration,
    });
    await setMessage(
      store,
      constants.MESSAGE_LOGIN_SUCCESS,
      constants.MESSAGE_TYPE_SUCCESS
    );
  }
  return data;
};

export const refreshToken = async store => {
  const { refresh } = store.state;
  const data = await authRequest(
      store,
      `refresh/`,
      'POST',
      { refresh },
      constants.TOKEN_REFRESH_ERROR,
      );
  if (data && data.access) {
    const { access: token } = data;
    localStorage.setItem('token', token);
    await store.setState({ token });
    return token;
  }
  // Refresh token has expired
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  await store.setState({ token: '', refresh: '' });
  await setMessage(
    store,
    constants.TOKEN_REFRESH_ERROR,
    constants.MESSAGE_TYPE_ERROR
  );
  return '';
};

export const resetPasswordRequest = async (store, email) => {
  const data = await passwordRequestReset(store,
      { email },
      constants.MESSAGE_RESET_PASSWORD_ERROR
  );
  if (data && data.message) {
    await setMessage(store, data.message, constants.MESSAGE_TYPE_INFO);
  }
  return data;
};

export const resetPasswordCommit = async (store, credentials) => {
  const data = await passwordDoReset(store, credentials);
  if (data && data.message) {
    await setMessage(store, data.message, constants.MESSAGE_TYPE_SUCCESS);
    return true;
  }
  return false;
};

export const verifyToken = async store => {
  const { token } = store.state;
  if (!token) {
    return false;
  }
  const url = `${constants.API_TOKEN_URL}verify/`;
  const response = await fetch(url, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ token }),
  });
  return response.ok;
};
