import React from 'react';
import useGlobalHook from 'use-global-hook';
import actions from './actions';

const initialState = {
  avatars: {},
  garage: [],
  driver: {},
  drivers: {},
  expiration: '',
  loadingDriver: true,
  loadingToken: true,
  message: '',
  messageType: '',
  refresh: '',
  storeFronts: {},
  token: '',
  username: '',
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
