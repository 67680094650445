import {
  setToken,
  clearToken,
  loadAuthData,
  loadToken,
  signUp,
  logout,
  confirmEmail,
  resetPasswordRequest,
  resetPasswordCommit,
} from './auth';
import {
  getGarage,
  addToGarage,
  registerNewCar,
  deleteCar,
  editCar,
} from './garage';
import { setMessage, clearMessage } from './message';
import {
  addDriver,
  deleteDriver,
  downloadLicense,
  editDriver,
  getDrivers,
  loadDriver,
  clearDriver,
  switchDriver,
} from './driver';
import getStoreFronts from './store-front';

import getAvatars from './avatar';

export default {
  addDriver,
  addToGarage,
  confirmEmail,
  clearMessage,
  clearToken,
  deleteCar,
  deleteDriver,
  downloadLicense,
  editCar,
  editDriver,
  getAvatars,
  getDrivers,
  getGarage,
  getStoreFronts,
  loadAuthData,
  loadDriver,
  loadToken,
  setMessage,
  setToken,
  signUp,
  switchDriver,
  logout,
  registerNewCar,
  clearDriver,
  resetPasswordRequest,
  resetPasswordCommit,
};
