import request from './request';
import { setMessage } from './message';
import * as constants from '../utils/constants';

// TODO remove after promotion
const checkDriver = async (store, driver) => {
  const unlocked = driver.is_license_unlocked;
  const config = {
    route: `driver/${driver.id}/`,
    method: 'GET',
    defaultErrorMessage: constants.MESSAGE_CHECK_LICENSE_ERROR
  };
  const newDriver = await request(store, config);
  if (!unlocked && newDriver && newDriver.is_license_unlocked) {
    store.setState({ driver: newDriver });
    await setMessage(
      store,
      "Congratulations, You've unlocked your driver's license! Click ”Edit Driver” to check it out.",
      constants.MESSAGE_TYPE_SUCCESS
    );
  }
};

export const getGarage = async (store, sort) => {
  const { driver } = store.state;
  let url = `garage/?driver=${driver.id}`;
  if (sort) url += `&ordering=${sort}`;
  const config = {
    route: url,
    method: 'GET',
    defaultErrorMessage: constants.MESSAGE_GET_GARAGE_ERROR
  };
  const data = await request(store, config);
  if (data) store.setState({ garage: data });
  return data;
};

export const addToGarage = async (store, item) => {
  // when updating car with configurator keep old car intact and create new car with the old license plate
  let result;
  const { driver } = store.state;
  const config = { route: `garage/${item.id}/`, method: 'GET'};
  if (item.id) {
    result = await request(store, config);
    await request(store, `garage/${item.id}/`, 'PATCH', {
      plate_number: '',
    });
  }
  const car = {
    configuration: JSON.stringify(item.configuration),
    image_url: item.imageUrl,
    name: item.carName,
    storefront: item.storefront,
    plate_number: (result && result.plate_number) || '',
    driver: driver.id,
  };
  const data = await request(store, `garage/`, 'POST', car, null, constants.MESSAGE_ADD_GARAGE_ERROR);
  if (data) {
    await checkDriver(store, driver);
  }
  await getGarage(store);
  return data;
};

export const registerNewCar = async (store, item) => {
  const { driver } = store.state;
  const car = {
    ...item,
    driver: driver.id,
  };
  const config = {
    route: `garage/`,
    method: 'POST',
    data: car,
    isBlob: null,
    defaultErrorMessage: constants.MESSAGE_REGISTER_CAR_ERROR
  };
  const data = await request(store, config);
  if (data) {
    await checkDriver(store, driver);
  }
  await getGarage(store);
  return data;
};

export const deleteCar = async (store, id) => {
  const config = {
    route: `garage/${id}/`,
    method: 'DELETE',
    defaultErrorMessage: constants.MESSAGE_DELETE_CAR_ERROR
  };
  const data = await request(store, config);
  await getGarage(store);
  return data;
};

export const editCar = async (store, id, fields) => {
  const { driver } = store.state;
  const config = {
    route: `garage/${id}/`,
    method: 'PATCH',
    data: fields,
    isBlob: null,
    defaultErrorMessage: constants.MESSAGE_EDIT_CAR_ERROR
  };
  const data = await request(store, config);
  if (data) {
    await checkDriver(store, driver);
  }
  await getGarage(store);
  return data;
};
