import request from './request';
import * as constants from '../utils/constants';

export const addDriver = async (store, driver) => {
  const config = {
    route: `driver/`,
    method: 'POST',
    data: driver,
    isBlob: null,
    defaultErrorMessage: constants.MESSAGE_ADD_DRIVER_ERROR,
  };
  const newDriver = await request(store, config);
  if (newDriver && newDriver.id) {
    const { drivers } = store.state;
    localStorage.setItem('driver', newDriver.id);
    store.setState({
      driver: newDriver,
      drivers: Object.assign({}, drivers, { [newDriver.id]: newDriver }),
    });
  }
  return newDriver;
};

export const deleteDriver = async (store, id) => {
  const { drivers, message } = store.state;
  const config = {
    route: `driver/${id}/`,
    method: 'DELETE',
    defaultErrorMessage: constants.MESSAGE_DELETE_LAST_DRIVER_ERROR,
  };
  const data = await request(store, config);
  // TODO find better way to check for successful deletion
  if (message !== constants.MESSAGE_DELETE_LAST_DRIVER_ERROR) {
    delete drivers[id];
    store.setState({ drivers });
    clearDriver(store);
    return data;
  }
  return null;
};

export const downloadLicense = async (store, id) => {
  const isBlob = true;
  const config = {
    route: `driver/${id}/drivers_license/`,
    method: 'GET',
    isBlob,
    defaultErrorMessage: constants.MESSAGE_DOWNLOAD_LICENSE_ERROR,
  };
  const pdf = await request(store, config);
  const blob = new Blob([pdf], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(blob);
  return window.open(pdfUrl);
};

export const editDriver = async (store, id, fields) => {
  const config = {
    route: `driver/${id}/`,
    method: 'PATCH',
    data: fields,
    defaultErrorMessage: constants.MESSAGE_EDIT_DRIVER_ERROR,
  };
  const driver = await request(store, config);
  if (driver && driver.id) {
    const { drivers } = store.state;
    store.setState({
      drivers: Object.assign({}, drivers, { [driver.id]: driver }),
      driver,
    });
  }
  return driver;
};

export const getDrivers = async store => {
  const config = {
    route: `driver/`,
    method: 'GET',
    defaultErrorMessage: constants.MESSAGE_GET_DRIVERS_ERROR,
  };
  const data = await request(store, config);
  if (data) {
    const drivers = data.reduce(
      (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
      {}
    );
    await store.setState({ drivers });
  }
  return data;
};

export const loadDriver = async store => {
  const id = localStorage.getItem('driver') || 0;
  const { drivers } = store.state;
  const driver = drivers[id] || {};
  await store.setState({ driver, loadingDriver: false });
  return driver;
};

export const clearDriver = store => {
  store.setState({ driver: {} });
  localStorage.removeItem('driver');
  return {};
};

export const switchDriver = (store, id) => {
  const { drivers } = store.state;
  localStorage.setItem('driver', id);
  store.setState({ driver: drivers[id] });
  return drivers[id];
};
